
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//tbdaerah routes
			{
				path: '/tbdaerah/:fieldName?/:fieldValue?',
				name: 'tbdaerahlist',
				component: () => import('./pages/tbdaerah/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbdaerah/view/:id', 
				name: 'tbdaerahview', 
				component: () => import('./pages/tbdaerah/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbdaerah/add', 
				name: 'tbdaerahadd', 
				component: () => import('./pages/tbdaerah/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbdaerah/edit/:id', 
				name: 'tbdaerahedit', 
				component: () => import('./pages/tbdaerah/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbdaerah/menudaerah/:fieldName?/:fieldValue?',
				name: 'tbdaerahmenudaerah',
				component: () => import('./pages/tbdaerah/menudaerah.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/tbdaerah/menudaerahadmin/:fieldName?/:fieldValue?',
				name: 'tbdaerahmenudaerahadmin',
				component: () => import('./pages/tbdaerah/menudaerahadmin.vue'), 
				props: route => passRouteToProps(route)
			},
	

//tbdatacp routes
			{
				path: '/tbdatacp/:fieldName?/:fieldValue?',
				name: 'tbdatacplist',
				component: () => import('./pages/tbdatacp/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbdatacp/view/:id', 
				name: 'tbdatacpview', 
				component: () => import('./pages/tbdatacp/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbdatacp/add', 
				name: 'tbdatacpadd', 
				component: () => import('./pages/tbdatacp/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbdatacp/edit/:id', 
				name: 'tbdatacpedit', 
				component: () => import('./pages/tbdatacp/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbdatacp/datacpadmin/:fieldName?/:fieldValue?',
				name: 'tbdatacpdatacpadmin',
				component: () => import('./pages/tbdatacp/datacpadmin.vue'), 
				props: route => passRouteToProps(route)
			},
	

//tbkehadiran routes
			{
				path: '/tbkehadiran/:fieldName?/:fieldValue?',
				name: 'tbkehadiranlist',
				component: () => import('./pages/tbkehadiran/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbkehadiran/view/:id', 
				name: 'tbkehadiranview', 
				component: () => import('./pages/tbkehadiran/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbkehadiran/add', 
				name: 'tbkehadiranadd', 
				component: () => import('./pages/tbkehadiran/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbkehadiran/edit/:id', 
				name: 'tbkehadiranedit', 
				component: () => import('./pages/tbkehadiran/edit.vue'), 
				props: true
			},
		

//tbmpul routes
			{
				path: '/tbmpul/:fieldName?/:fieldValue?',
				name: 'tbmpullist',
				component: () => import('./pages/tbmpul/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbmpul/view/:id', 
				name: 'tbmpulview', 
				component: () => import('./pages/tbmpul/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbmpul/add', 
				name: 'tbmpuladd', 
				component: () => import('./pages/tbmpul/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbmpul/edit/:id', 
				name: 'tbmpuledit', 
				component: () => import('./pages/tbmpul/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbmpul/mpul_menu/:fieldName?/:fieldValue?',
				name: 'tbmpulmpul_menu',
				component: () => import('./pages/tbmpul/mpul_menu.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/tbmpul/menu_ppd/:fieldName?/:fieldValue?',
				name: 'tbmpulmenu_ppd',
				component: () => import('./pages/tbmpul/menu_ppd.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbmpul/viewmenump_ppd/:id', 
				name: 'tbmpulviewmenump_ppd', 
				component: () => import('./pages/tbmpul/viewmenump_ppd.vue'), 
				props: true
			},
		

//tbpusatbertuliscp routes
			{
				path: '/tbpusatbertuliscp/:fieldName?/:fieldValue?',
				name: 'tbpusatbertuliscplist',
				component: () => import('./pages/tbpusatbertuliscp/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbpusatbertuliscp/view/:id', 
				name: 'tbpusatbertuliscpview', 
				component: () => import('./pages/tbpusatbertuliscp/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbpusatbertuliscp/add', 
				name: 'tbpusatbertuliscpadd', 
				component: () => import('./pages/tbpusatbertuliscp/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbpusatbertuliscp/edit/:id', 
				name: 'tbpusatbertuliscpedit', 
				component: () => import('./pages/tbpusatbertuliscp/edit.vue'), 
				props: true
			},
		

//tbpusatpentaksir routes
			{
				path: '/tbpusatpentaksir/:fieldName?/:fieldValue?',
				name: 'tbpusatpentaksirlist',
				component: () => import('./pages/tbpusatpentaksir/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbpusatpentaksir/view/:id', 
				name: 'tbpusatpentaksirview', 
				component: () => import('./pages/tbpusatpentaksir/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbpusatpentaksir/add', 
				name: 'tbpusatpentaksiradd', 
				component: () => import('./pages/tbpusatpentaksir/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbpusatpentaksir/edit/:id', 
				name: 'tbpusatpentaksiredit', 
				component: () => import('./pages/tbpusatpentaksir/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbpusatpentaksir/statusmarkahpusat/:fieldName?/:fieldValue?',
				name: 'tbpusatpentaksirstatusmarkahpusat',
				component: () => import('./pages/tbpusatpentaksir/statusmarkahpusat.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/tbpusatpentaksir/dbstatuspusat/:fieldName?/:fieldValue?',
				name: 'tbpusatpentaksirdbstatuspusat',
				component: () => import('./pages/tbpusatpentaksir/dbstatuspusat.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbpusatpentaksir/editadmin/:id', 
				name: 'tbpusatpentaksireditadmin', 
				component: () => import('./pages/tbpusatpentaksir/editadmin.vue'), 
				props: true
			},
		
			{
				path: '/tbpusatpentaksir/dbstatusadmin/:fieldName?/:fieldValue?',
				name: 'tbpusatpentaksirdbstatusadmin',
				component: () => import('./pages/tbpusatpentaksir/dbstatusadmin.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/tbpusatpentaksir/statusmarkahppd/:fieldName?/:fieldValue?',
				name: 'tbpusatpentaksirstatusmarkahppd',
				component: () => import('./pages/tbpusatpentaksir/statusmarkahppd.vue'), 
				props: route => passRouteToProps(route)
			},
	

//tbpusatujiancp routes
			{
				path: '/tbpusatujiancp/:fieldName?/:fieldValue?',
				name: 'tbpusatujiancplist',
				component: () => import('./pages/tbpusatujiancp/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbpusatujiancp/view/:id', 
				name: 'tbpusatujiancpview', 
				component: () => import('./pages/tbpusatujiancp/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbpusatujiancp/add', 
				name: 'tbpusatujiancpadd', 
				component: () => import('./pages/tbpusatujiancp/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbpusatujiancp/edit/:id', 
				name: 'tbpusatujiancpedit', 
				component: () => import('./pages/tbpusatujiancp/edit.vue'), 
				props: true
			},
		

//tbtahun routes
			{
				path: '/tbtahun/:fieldName?/:fieldValue?',
				name: 'tbtahunlist',
				component: () => import('./pages/tbtahun/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbtahun/view/:id', 
				name: 'tbtahunview', 
				component: () => import('./pages/tbtahun/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbtahun/add', 
				name: 'tbtahunadd', 
				component: () => import('./pages/tbtahun/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbtahun/edit/:id', 
				name: 'tbtahunedit', 
				component: () => import('./pages/tbtahun/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbtahun/tahun_menu/:fieldName?/:fieldValue?',
				name: 'tbtahuntahun_menu',
				component: () => import('./pages/tbtahun/tahun_menu.vue'), 
				props: route => passRouteToProps(route)
			},
	

//tbujiancp routes
			{
				path: '/tbujiancp/:fieldName?/:fieldValue?',
				name: 'tbujiancplist',
				component: () => import('./pages/tbujiancp/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbujiancp/view/:id', 
				name: 'tbujiancpview', 
				component: () => import('./pages/tbujiancp/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbujiancp/add', 
				name: 'tbujiancpadd', 
				component: () => import('./pages/tbujiancp/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbujiancp/edit/:id', 
				name: 'tbujiancpedit', 
				component: () => import('./pages/tbujiancp/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbujiancp/masuk_markah/:fieldName?/:fieldValue?',
				name: 'tbujiancpmasuk_markah',
				component: () => import('./pages/tbujiancp/masuk_markah.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/tbujiancp/ulcp_ppd/:fieldName?/:fieldValue?',
				name: 'tbujiancpulcp_ppd',
				component: () => import('./pages/tbujiancp/ulcp_ppd.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbujiancp/editmarkah/:id', 
				name: 'tbujiancpeditmarkah', 
				component: () => import('./pages/tbujiancp/editmarkah.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		
			{
				path: '/users/viewlist/:fieldName?/:fieldValue?',
				name: 'usersviewlist',
				component: () => import('./pages/users/viewlist.vue'), 
				props: route => passRouteToProps(route)
			},
	

			{ 
				path: '/menu_setting', 
				name: 'menu_setting', 
				component: () => import('././pages/custom/menu_setting.vue'), 
				props: true
			},
	
	
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		const roleName = auth.userRole.toLowerCase();
		let rolePage;
		switch(roleName){
			case "pentaksir":
				rolePage = "pentaksir";
				break;
			case "ppd":
				rolePage = "ppd";
				break;
			default:
				rolePage = "home";
		}
		
		//Dashboard route
		//Display page based on user role
		routes.push({
			path: '/',
			alias: '/home', 
			name: 'home', 
			component: () => import(`./pages/home/${rolePage}.vue`),
			props: true
		});
	}
	else{
		routes.push({
			path: '/', 
			alias: '/index', 
			name: 'index', 
			component: () => import('./pages/index/index.vue'),
			props: true
		});
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
