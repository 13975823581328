
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Dashboard",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbdaerah/menudaerahadmin",
    "label": "Data Calon",
    "icon": "pi pi-database",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtahun/tahun_menu",
    "label": "Masuk Markah",
    "icon": "pi pi-desktop",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbujiancp",
    "label": "Laporan",
    "icon": "pi pi-book",
    "iconcolor": "",
    "target": "_self",
    
  },
  {
    "to": "/tbdaerah/menudaerah",
    "label": "Data Calon",
    "icon": "pi pi-database",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/menu_setting",
    "label": "Konfigurasi",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    
  }
],
	status: [    
{value: "BUKA", label: "BUKA"},
	{value: "TUTUP", label: "TUTUP"}
    ],
	status2: [    
{value: "AKTIF", label: "AKTIF"},
	{value: "TIDAK AKTIF", label: "TIDAK AKTIF"}
    ],
	statusMarkah: [    
{value: "SELESAI", label: "SELESAI"},
	{value: "BELUM SELESAI", label: "BELUM SELESAI"}
    ],
	statusMarkah2: [{"label":"Selesai","value":"SELESAI"},{"label":"Belum Selesai","value":"BELUM SELESAI"}],
	menusettingMenuSettingStaticmenu: [
  {
    "to": "/tbtahun/",
    "label": "Senarai Tahun",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbmpul/",
    "label": "Mata Pelajaran",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbdaerah/",
    "label": "Daerah",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbpusatbertuliscp/",
    "label": "Pusat Bertulis",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbpusatpentaksir/",
    "label": "Pusat Pentaksir",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbpusatujiancp/",
    "label": "Pusat Ujian",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users/",
    "label": "Pengguna",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/permissions/",
    "label": "Permission",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles/",
    "label": "Peranan",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}